/**
 * Unique function for array of objects
 *
 * @param prop - name of prop which is the unique index
 * @param array - with objects
 *
 * @return array with unique objects
 */
export const unique = (uniqueProp, array) => {
    return array.filter((obj, i, arr) => {
        return arr.findIndex(prevObj => prevObj[uniqueProp] === obj[uniqueProp]) === i;
    });
};
