//
// Mappers to reduce store/ boilerplate
//

/**
 * Map 3 props from 1 - prop, propPending and propError
 *
 * @param {String} prop
 * @param {Any} initialState
 */
export const mapPropPendingError = (prop, initialState) => {
    return {
        [prop]: initialState,
        [`${prop}Pending`]: false,
        [`${prop}Error`]: null,
    };
};

/**
 * Map 3 mutations from 1 - MUTATION, MUTATION_PENDING and MUTATION_ERROR
 *
 * @param {String} name of mutation
 * @param {String} prop which is mutated
 * @param {Function} onSuccess
 * @param {Function} onPending
 * @param {Function} onError
 */
export const mapMutationPendingError = (name, prop, onSuccess, onPending = null, onError = null) => {
    return {
        [name]: (state, payload) => {
            state[`${prop}Pending`] = false;
            state[`${prop}Error`] = null;
            onSuccess(state, payload);
        },
        [`${name}_PENDING`]: onPending
            ? (state, payload = {}) => {
                state[`${prop}Pending`] = false;
                state[`${prop}Error`] = null;
                onPending(state, payload);
            }
            : (state) => {
                state[`${prop}Pending`] = false;
                state[`${prop}Error`] = null;
            },

        [`${name}_ERROR`]: onError
            ? (state, err = {}) => {
                state[`${prop}Pending`] = false;
                state[`${prop}Error`] = err;
                onError(state, err);
            }
            : (state, err = {}) => {
                state[`${prop}Pending`] = false;
                state[`${prop}Error`] = err;
            },
    };
};
