import axios from 'axios';
import { unique } from '@/libs/unique';
import { mapMutationPendingError, mapPropPendingError } from '../mappers.js';

// Actions
export const fetchContacts = 'fetchContacts';
export const fetchAllContacts = 'fetchAllContacts';
export const fetchAllCategories = 'fetchAllCategories';
export const fetchLastUsedContacts = 'fetchLastUsedContacts';
export const addLastUsedContact = 'addLastUsedContact';
export const companyCategories = 'companyCategories';

const state = {
    ...mapPropPendingError('all', []),
    ...mapPropPendingError('lastUsed', []),
    ...mapPropPendingError('categories', []),
};

const mutations = {
    ...mapMutationPendingError('CONTACTS_SET', 'all', (state, all) => {
        state.all = all;
    }),
    ...mapMutationPendingError('CONTACTS_LASTUSED_SET', 'lastUsed', (state, lastUsed) => {
        state.lastUsed = lastUsed;
    }),
    ...mapMutationPendingError('CATEGORIES_SET', 'categories', (state, categories) => {
        state.categories = categories;
    }),
    CONTACT_LASTUSED_ADD: (state, { contact, persons, existingPersons }) => {
        let newContact = {
            ...contact,
            persons: unique('id', [...persons, ...existingPersons]),
        };
        state.lastUsed = unique('id', [newContact, ...state.lastUsed]);
    },
};

const actions = {
    // Fetch *all* contacts, meaning including those who don't have emails set (?all=1 in request)
    // Currently only used for selecting contacts for projects. Can be removed when kk-contacts
    // support selecting contacts and persons that don't have email/phone set.
    fetchAllContacts: ({ commit }) => {
        commit('CONTACTS_SET_PENDING');

        return axios.get('/api/contacts?all=1', {
            params: {
                display: '0',
                include: 'addresses.country,categories,persons,projects',
            },
        }).then((contacts) => {
            commit('CONTACTS_SET', contacts.data);
        }, (err) => {
            commit('CONTACTS_SET_ERROR', err);
        });
    },
    fetchAllCategories: async ({ commit }) => {
        commit('CONTACTS_SET_PENDING');

        try {
            const categories = await axios.get('/api/contact-categories');
            commit('CATEGORIES_SET', categories.data);
        } catch (error) {
            commit('CONTACTS_SET_ERROR', error);
        }
    },
    fetchContacts: ({ commit }) => {
        commit('CONTACTS_SET_PENDING');

        return axios.get('/api/contacts', {
            params: {
                display: '0',
                include: 'addresses.country,categories,persons,projects',
            },
        })
            .then((contacts) => {
                commit('CONTACTS_SET', contacts.data);
            }, (err) => {
                commit('CONTACTS_SET_ERROR', err);
            });
    },

    /**
     * Fetches contacts and employees to use in
     * kk-contacts. Uses vuex to handle users.
     * INFO: This function uses rootState and is communicating
     * between vuex modules!
    */
    async fetchContactsAndEmployees({ rootState, dispatch }) {
        await dispatch('fetchContacts', undefined, { root: true });
        await dispatch('fetchAllCategories', undefined, { root: true });
        await dispatch('employees/loadList', undefined, { root: true });

        const employeeList = rootState.employees.list.filter(f => f.disabled === false);

        const formatPersons = persons => persons.map(employee => ({
            id: employee.id,
            contactId: 0,
            name: employee.name,
            email: employee.email,
            mobile: employee.mobile,
            type: 'employee',
            category: Lang.get('sms-dialog.Ansatte'),
        }));

        const formattedEmployees = {
            id: 'employees',
            name: Lang.get('sms-dialog.Ansatte'),
            email: '',
            mobile: '',
            persons: formatPersons(employeeList),
            category: Lang.get('sms-dialog.Ansatte'),
            sortFirst: true,
        };

        dispatch('addContacts', [formattedEmployees], { root: true });
    },

    addContacts: ({ state, commit }, moreContacts = []) => {
        commit('CONTACTS_SET', [...state.all, ...moreContacts]);
    },

    fetchLastUsedContacts: ({ commit }, inspectionId) => {
        commit('CONTACTS_LASTUSED_SET_PENDING');

        axios.get(`/api/inspections/${inspectionId}/lastUsedContacts`)
            .then((lastUsed) => {
                commit('CONTACTS_LASTUSED_SET', lastUsed.data.data);
            }, (err) => {
                commit('CONTACTS_LASTUSED_SET_ERROR', err);
            });
    },

    addLastUsedContact: ({ state, commit }, { contactId, personId = null }) => {
        let contact = state.all.find(contact => contact.id === contactId);

        if (!contact) {
            return; // @BUG
        }

        let existingContact = state.lastUsed.find(existing => existing.id === contact.id);

        if (!personId && !existingContact) {
            commit('CONTACT_LASTUSED_ADD', { contact, persons: [], existingPersons: [] });

            return;
        }

        if (!personId && existingContact) {
            commit('CONTACT_LASTUSED_ADD', { contact, persons: [], existingPersons: existingContact.persons });

            return;
        }

        let person = contact.persons.find(person => person.id === personId);

        if (!person) {
            return; // @BUG
        }

        if (!existingContact) {
            commit('CONTACT_LASTUSED_ADD', { contact, persons: [person], existingPersons: [] });

            return;
        }

        commit('CONTACT_LASTUSED_ADD', { contact, persons: [person], existingPersons: existingContact.persons });
    },
};

export const contacts = {
    state,
    mutations,
    actions,
};
